(function($) {

    $(document).ready(function() {

        var please_wait_options = {
            logo: null,
            backgroundColor: '#fff',
            loadingHtml: '<div class="spinner"><div class="bounce1"></div> <div class="bounce2"></div> <div class="bounce3"></div> </div>'
        };

        $('.side-menu a:not(#home-link)').click(function(e){
            e.preventDefault();
            var id = $(this).attr('href');
            if(id == '#simulation'){
                $('.content-container').fadeOut().promise().done(function () {
                    $('#simulation-results,#simulation-extra-info,#simulation-message').hide();
                    $(id).fadeIn();
                    getCarsSimulation();
                });
            }
            else {
                $('.content-container').fadeOut().promise().done(function () {
                    $(id).fadeIn();
                });
            }

        });

        $('#update-km-form .update-km-label').click(function(){
            $('#update-km-form .update-km-inner').toggle();
        });

        $('#messages-link').click(function(e){
            e.preventDefault();
            $('.content-container').fadeOut().promise().done(function(){
                $('#messages-content').fadeIn();
            });


            $.ajax({
                url: 'index.php?option=com_benefit&task=data.resetUserUnreadMsgCounter',
                data: '',
                type: 'POST',
                dataType: 'json',
                success: function (response) {
                    if(response.status){

                        $('.badge').html('0');

                    }
                    else{
                        //console.log(response.message);
                    }

                },
                error: function (xhr, status, error) {

                    console.log(error);

                }
            });

        });

        $('#simulation-form select,#all-messages select').niceSelect();


        $('#get-code-button').click(function(e){

            e.preventDefault();

            var phone = $('#modlgn-phone').val();
            var id_num = $('#modlgn-id-num').val();

            var obj = {
                phone: phone,
                id_num: id_num
            };

            $.ajax({
                url: 'index.php?option=com_benefit&task=data.smsLogin',
                data: obj,
                type: 'POST',
                dataType: 'json',
                success: function (response) {
                    if(response.status){

                        //console.log(response.code);

                        var data = response.data;

                        if(isset(data.status) && data.status == '0'){
                            //console.log(data.message);

                            $('#modlgn-username').val(response.username);

                            $('#modlgn-phone-form').fadeOut().promise().done(function(){
                                $('#login-form').fadeIn();
                            });
                        }
                        else{
                            //console.log(data.message);
                        }
                    }
                    else{
                        console.log(response.message);
                        alert('אחד השדות שגוי, אנא נסה שנית')
                    }

                },
                error: function (xhr, status, error) {

                    console.log(error);

                }
            });

        });


        $('#upload-simulation-csv').click(function(e){

            e.preventDefault();

            es = new EventSource('./data.php');

            //a message is received
            es.addEventListener('message', function(e) {
                var result = JSON.parse( e.data );

                addLog(result.message);

                if(e.lastEventId == 'CLOSE') {
                    addLog('Received CLOSE closing');
                    es.close();
                    var pBar = document.getElementById('progressor');
                    pBar.value = pBar.max; //max out the progress bar
                }
                else {
                    var pBar = document.getElementById('progressor');
                    pBar.value = result.progress;
                    var perc = document.getElementById('percentage');
                    perc.innerHTML   = result.progress  + "%";
                    perc.style.width = (Math.floor(pBar.clientWidth * (result.progress/100)) + 15) + 'px';
                }
            });

            es.addEventListener('error', function(e) {
                addLog('Error occurred');
                es.close();
            });

        });

        function isset() {
            var a = arguments,
                l = a.length,
                i = 0,
                undef;

            if (l === 0) {
                throw new Error('Empty isset');
            }

            while (i !== l) {
                if (a[i] === undef || a[i] === null) {
                    return false;
                }
                i++;
            }
            return true;
        }

        function getCarsSimulation(){

            var loading_screen = pleaseWait(please_wait_options);

            $.ajax({
                url: 'index.php?option=com_benefit&task=data.getCarsSimulation',
                data: '',
                type: 'POST',
                dataType: 'json',
                success: function (response) {

                    if(response.status){

                        $('#simulation-form').show();

                        //console.log(response.data);

                        var cars = response.data;


                        if(cars.length > 0) {
                            $.each(cars, function (index, value) {
                                $('#car-select').append('<option data-leasing-company="' + value[2] + '" data-price="' + value[1] + '" value="' + value[0] + '">' + value[0] + '</option>')
                            });

                            $('#car-select').niceSelect('update');
                        }
                        else{
                            $('.simulation-no-results-hide').hide();
                            $('.simulation-no-results-message').show();
                        }

                        loading_screen.finish();
                    }
                    else{
                        console.log(response.data);
                    }

                },
                error: function (xhr, status, error) {

                    console.log(error);

                }
            });
        }




        $('#car-select').change(function(){

            var loading_screen = pleaseWait(please_wait_options);

            var car_type = $('option:selected',this).val();

            /* disabled submit button because now fuel cover not selected */
            $("#simulation-btn").prop("disabled", true);

            var obj = {
                car_type: car_type
            };

            $('#fuel-select').find('option').not(':first').remove();

            $.ajax({
                url: 'index.php?option=com_benefit&task=data.getFuelCoverSimulation',
                data: obj,
                type: 'POST',
                dataType: 'json',
                success: function (response) {

                    if(response.status){

                        //console.log(response.data);

                        var fuel = response.data;

                        $.each(fuel, function( index, value ) {
                            $('#fuel-select').append('<option value="'+value[0]+'">'+value[0]+'</option>')
                        });

                        $('#fuel-select').niceSelect('update');

                        loading_screen.finish();
                    }
                    else{
                        console.log(response.data);
                    }

                },
                error: function (xhr, status, error) {

                    console.log(error);

                }
            });

        });

        $('#fuel-select').change(function() {

            var fuel_cover = $('option:selected', this).val();
            var km_cover = $('#km-select option:selected').val();

            if(fuel_cover!='' && km_cover != ''){
                $("#simulation-btn").prop("disabled", false);
            }
            else{
                $("#simulation-btn").prop("disabled", true);
            }
        });

        $('#km-select').change(function() {

            var km_cover = $('option:selected', this).val();
            var fuel_cover = $('#fuel-select option:selected').val();

            if(km_cover!='' && fuel_cover!=''){
                $("#simulation-btn").prop("disabled", false);
            }
            else{
                $("#simulation-btn").prop("disabled", true);
            }
        });

        $("#simulation-btn").click(function(e){

            e.preventDefault();

            var loading_screen = pleaseWait(please_wait_options);

            var car_type = $('#car-select option:selected').val();
            var fuel_cover = $('#fuel-select option:selected').val();
            var km_cover = $('#km-select option:selected').val();
            var accessory_price_1 = $('#accessory_price_1').val();
            var accessory_price_2 = $('#accessory_price_2').val();
            var accessory_price_3 = $('#accessory_price_3').val();

            var obj = {
                car_type : car_type,
                fuel_cover : fuel_cover,
                km_cover : km_cover,
                accessory_price_1: accessory_price_1,
                accessory_price_2 : accessory_price_2,
                accessory_price_3 : accessory_price_3
            };

            var display_simulation_results = $('#simulation-results');
            var print_simulation_results =  $('#print-simulation-results');

            display_simulation_results.fadeOut();

            $.ajax({
                url: 'index.php?option=com_benefit&task=data.getSimulationResult',
                data: obj,
                type: 'POST',
                dataType: 'json',
                success: function (response) {

                    if(response.status){

                        if(isset(response.data['raw_data_results'])){
                            var raw_data_results = response.data['raw_data_results'];

                            /* display
                            * =========*/
                            $('.sal_pns,.sal_pns_new',display_simulation_results).val(raw_data_results['sal_pns']);
                            $('.achzakat_car,.achzakat_car_new',display_simulation_results).val(raw_data_results['achzakat_car']);
                            $('.nesiot_pizul_kayam,.nesiot_pizul_new',display_simulation_results).val(raw_data_results['nesiot_pizul_kayam']);
                            $('.car_keshev_km_pizul,.car_keshev_km_pizul_new',display_simulation_results).val(raw_data_results['car_keshev_km_pizul']);
                            $('.km_meduvach',display_simulation_results).val(raw_data_results['km_meduvach']);
                            $('.shovi_kavua',display_simulation_results).val(raw_data_results['shovi_kavua']);

                            /* print
                            * =======*/
                            $('.sal_pns,.sal_pns_new',print_simulation_results).html(raw_data_results['sal_pns']);
                            $('.achzakat_car,.achzakat_car_new',print_simulation_results).html(raw_data_results['achzakat_car']);
                            $('.nesiot_pizul_kayam,.nesiot_pizul_new',print_simulation_results).html(raw_data_results['nesiot_pizul_kayam']);
                            $('.car_keshev_km_pizul,.car_keshev_km_pizul_new',print_simulation_results).html(raw_data_results['car_keshev_km_pizul']);
                            $('.km_meduvach',print_simulation_results).html(raw_data_results['km_meduvach']);
                            $('.shovi_kavua',print_simulation_results).html(raw_data_results['shovi_kavua']);
                        }

                        if(isset(response.data['simulation_results'])) {
                            var simulation_results = response.data['simulation_results'];

                            /* display
                            * =========*/
                            $('.aher_kayam',display_simulation_results).val(simulation_results['aher_kayam']);
                            $('.sum_kayam',display_simulation_results).val(simulation_results['sum_kayam']);
                            $('.tax_kayam',display_simulation_results).val(simulation_results['tax_kayam']);
                            $('.bl_briot_kayam',display_simulation_results).val(simulation_results['bl_briot_kayam']);
                            $('.keren_histalmot_kayam',display_simulation_results).val(simulation_results['keren_histalmot_kayam']);
                            $('.tagmoli_oved_kayam',display_simulation_results).val(simulation_results['tagmoli_oved_kayam']);
                            $('.sal_neto_kayam',display_simulation_results).val(simulation_results['sal_neto_kayam']);
                            $('.km_new',display_simulation_results).val(simulation_results['km_new']);
                            $('.aher_new',display_simulation_results).val(simulation_results['aher_new']);
                            $('.hafhata_leasing',display_simulation_results).val(simulation_results['hafhata_leasing']);
                            $('.sum_new',display_simulation_results).val(simulation_results['sum_new']);
                            $('.shovi_new',display_simulation_results).val(simulation_results['shovi_new']);
                            $('.tax_new',display_simulation_results).val(simulation_results['tax_new']);
                            $('.bl_briot_new',display_simulation_results).val(simulation_results['bl_briot_new']);
                            $('.keren_hishtalmot_new',display_simulation_results).val(simulation_results['keren_hishtalmot_new']);
                            $('.tagmoli_oved_new',display_simulation_results).val(simulation_results['tagmoli_oved_new']);
                            $('.sal_neto_new',display_simulation_results).val(simulation_results['sal_neto_new']);
                            $('.yerida_neto_new',display_simulation_results).val(simulation_results['yerida_neto_new']);
                            $('.comment_about_the_car',display_simulation_results).html('*'+simulation_results['comment_about_the_car']);
                            $('.comment_about_mehir',display_simulation_results).html('*'+simulation_results['comment_about_mehir']);
                            $('.mehir_mekori',display_simulation_results).val(simulation_results['mehir_mekori']);
                            $('.mehir_sofi',display_simulation_results).val(simulation_results['mehir_sofi']);
                            $('.car_type',display_simulation_results).val(simulation_results['car_type']);
                            $('.leasing_company',display_simulation_results).val(simulation_results['leasing_company']);
                            $('.car_price',display_simulation_results).val(simulation_results['car_price']);
                            $('.michsat_delek',display_simulation_results).val(simulation_results['michsat_delek']);
                            $('.yami_hofesh',display_simulation_results).val(simulation_results['yami_hofesh']);
                            $('.hofesh',display_simulation_results).val(simulation_results['hofesh']);
                            $('.mehirsofi',display_simulation_results).val(simulation_results['mehirsofi']);
                            $('.date',display_simulation_results).val(simulation_results['date']);
                            $('.girsa',display_simulation_results).val(simulation_results['girsa']);
                            $('.years',display_simulation_results).val(simulation_results['years']);
                            $('.private_leasing',display_simulation_results).val(simulation_results['private_leasing']);
                            $('.private_expanse',display_simulation_results).val(simulation_results['private_expanse']);
                            $('.neto_after_private_expanse',display_simulation_results).val(simulation_results['neto_after_private_expanse']);
                            $('.neto_after_private_leasing',display_simulation_results).val((parseInt(simulation_results['sal_neto_kayam'].replace(',',''))-parseInt(simulation_results['private_leasing'].replace(',',''))).toLocaleString());
                            if(simulation_results['mehir_sofi'] != '0'){
                                $('.mehir_mekori',display_simulation_results).html('מחיר עסקת הליסינג המלא: ' + simulation_results['mehir_mekori']);
                            }
                            else{
                                $('.mehir_mekori',display_simulation_results).html('');
                            }


                            /* hidden for pdf */
                            $('#yami_hofesh').val(simulation_results['yami_hofesh']);
                            $('#hofesh').val(simulation_results['hofesh']);
                            $('#mehir_mekori').val(simulation_results['mehir_mekori']);
                            $('#mehir_sofi').val(simulation_results['mehir_sofi']);


                            /* print
                            * =======*/
                            $('.aher_kayam',print_simulation_results).html(simulation_results['aher_kayam']);
                            $('.sum_kayam',print_simulation_results).html(simulation_results['sum_kayam']);
                            $('.tax_kayam',print_simulation_results).html(simulation_results['tax_kayam']);
                            $('.bl_briot_kayam',print_simulation_results).html(simulation_results['bl_briot_kayam']);
                            $('.keren_histalmot_kayam',print_simulation_results).html(simulation_results['keren_histalmot_kayam']);
                            $('.tagmoli_oved_kayam',print_simulation_results).html(simulation_results['tagmoli_oved_kayam']);
                            $('.sal_neto_kayam',print_simulation_results).html(simulation_results['sal_neto_kayam']);
                            $('.km_new',print_simulation_results).html(simulation_results['km_new']);
                            $('.aher_new',print_simulation_results).html(simulation_results['aher_new']);
                            $('.hafhata_leasing',print_simulation_results).html(simulation_results['hafhata_leasing']);
                            $('.sum_new',print_simulation_results).html(simulation_results['sum_new']);
                            $('.shovi_new',print_simulation_results).html(simulation_results['shovi_new']);
                            $('.tax_new',print_simulation_results).html(simulation_results['tax_new']);
                            $('.bl_briot_new',print_simulation_results).html(simulation_results['bl_briot_new']);
                            $('.keren_hishtalmot_new',print_simulation_results).html(simulation_results['keren_hishtalmot_new']);
                            $('.tagmoli_oved_new',print_simulation_results).html(simulation_results['tagmoli_oved_new']);
                            $('.sal_neto_new',print_simulation_results).html(simulation_results['sal_neto_new']);
                            $('.yerida_neto_new',print_simulation_results).html(simulation_results['yerida_neto_new']);
                            $('.comment_about_the_car',print_simulation_results).html('*'+simulation_results['comment_about_the_car']);
                            $('.comment_about_mehir',print_simulation_results).html('*'+simulation_results['comment_about_mehir']);
                            $('.mehir_mekori',print_simulation_results).html(simulation_results['mehir_mekori']);
                            $('.mehir_sofi',print_simulation_results).html(simulation_results['mehir_sofi']);
                            $('.car_type',print_simulation_results).html(simulation_results['car_type']);
                            $('.leasing_company',print_simulation_results).html(simulation_results['leasing_company']);
                            $('.car_price',print_simulation_results).html(simulation_results['car_price']);
                            $('.michsat_delek',print_simulation_results).html(simulation_results['michsat_delek']);
                            $('.yami_hofesh',print_simulation_results).html(simulation_results['yami_hofesh']);
                            $('.hofesh',print_simulation_results).html(simulation_results['hofesh']);
                            $('.mehirsofi',print_simulation_results).html(simulation_results['mehirsofi']);
                            $('.date',print_simulation_results).html(simulation_results['date']);
                            $('.girsa',print_simulation_results).html(simulation_results['girsa']);
                            $('.years',print_simulation_results).html(simulation_results['years']);
                            $('.private_leasing',print_simulation_results).html(simulation_results['private_leasing']);
                            $('.private_expanse',print_simulation_results).html(simulation_results['private_expanse']);
                            $('.neto_after_private_expanse',print_simulation_results).html(simulation_results['neto_after_private_expanse']);
                            $('.neto_after_private_leasing',print_simulation_results).html((parseInt(simulation_results['sal_neto_kayam'].replace(',',''))-parseInt(simulation_results['private_leasing'].replace(',',''))).toLocaleString());
                            if(simulation_results['mehir_sofi'] != '0'){
                                $('.mehir_mekori',print_simulation_results).html('מחיר עסקת הליסינג המלא: ' + simulation_results['mehir_mekori']);
                            }
                            else{
                                $('.mehir_mekori',print_simulation_results).html('');
                            }
                        }

                        if(isset(response.data['accessory_total'])) {
                            var accessory_total = response.data['accessory_total'];
                            $('.total-price').val(accessory_total);
                            $('#total-accessory').fadeIn();
                        }




                        display_simulation_results.fadeIn();

                        equalHeights();

                        loading_screen.finish();
                    }
                    else{
                        console.log(response.data);
                    }

                },
                error: function (xhr, status, error) {

                    console.log(error);

                }
            });

        });


        //if ($("#datatable-buttons-1").length) {
        //    getCarsSimulation();
        //}

        //$("#simulation-link").click(function(){
        //
        //});


        $('#messages-form').submit(function (e) {

            e.preventDefault();

            var form = $(this);

            //product add to cart
            $.ajax({
                url: 'index.php?option=com_benefit&task=data.sendMessage',
                data: form.serialize(),
                type: 'POST',
                dataType: 'json',
                success: function (response) {

                    if (response.status) {

                        $('#message_title,#message_text').val('');
                        $('#messages-content .alert-success').fadeIn();
                        //console.log('success');

                    } else {

                        console.log('error');

                    }

                },
                error: function (xhr, status, error) {

                    console.log(error);

                }
            });
        });

        $('#send-message-form').submit(function (e) {

            e.preventDefault();

            var form = $('#send-message-form');
            //var form = document.getElementById('send-message-form');
            //var fileSelect = document.getElementById('file_upload');// Get the selected files from the input.
            //var files = fileSelect.files;

            //var formData = new FormData();

            //formData.append("message_title", $('input[name="message_title"]').val());
            //formData.append("send_to", "daniel.a@anova.co.il");
            //formData.append("message_text", $('textarea[name="message_text"]').val());
            //formData.append("file_upload", $('input[name="file_upload"]').files[0]);

            // Loop through each of the selected files.
            //for (var i = 0; i < files.length; i++) {
            //    var file = files[i];

                // Check the file type.
                //if (!file.type.match('image.*')) {
                //    continue;
                //}

                // Add the file to the request.
            //    formData.append('files[]', file, file.name);
            //}

            //product add to cart
            $.ajax({
                url: 'index.php?option=com_benefit&task=data.sendEmail',
                //data: formData,
                data: form.serialize(),
                type: 'POST',
                dataType: 'json',
                success: function (response) {

                    if (response.status) {

                        $('.message_title,.message_text').val('');
                        $('#messages-content .alert-success').fadeIn();
                        //console.log('success');

                    } else {

                        console.log('error');

                    }

                },
                error: function (xhr, status, error) {

                    console.log(error);

                }
            });
        });

        $("#print-simulation").click(function(){
            $("#print-simulation-results").printThis();
        });


        $('input').on('ifChecked', function(event){
            alert(event.type + ' callback');
        });


        //$("#check-all").change(function(){
        //    console.log('click');
        //    $('#datatable-checkbox tbody input[type="checkbox"]').prop('checked', this.checked);
        //});


        $("#aaa").change(function(){
            console.log('click');
            $('#datatable-checkbox tbody input[type="checkbox"]').prop('checked', this.checked);
        });


        $(".upload").click(function(e){

            var es;
            var percent = 0;
            var upload_container = $(this).parent();
            var progress_bar = upload_container.find('.progress-bar');
            var numbers = upload_container.find('.numbers');

            progress_bar.css('width', percent+'%').attr('aria-valuenow', percent);

            var file_name = $(this).data('file-name');


            //index.php?option=com_benefit&task=data.uploadRowDataBll

            es = new EventSource('index.php?option=com_benefit&task=data.'+file_name);

            es.addEventListener('message', function(e) {
                var result = JSON.parse( e.data );

                numbers.html(result.message);

                if(e.lastEventId == 'CLOSE') {
                    es_users.close();
                }
                else {
                    var percent = result.progress;
                    progress_bar.css('width', percent+'%').attr('aria-valuenow', percent);
                }
            });

            es.addEventListener('error', function(e) {
                es.close();
            });
        });


        $('#confirm-simulation').click(function(e){
            e.preventDefault();
            $('#simulation-results,#simulation-form,#simulation-send-pdf,#simulation-edit-pdf').fadeOut().promise().done(function(){
                $('#simulation-extra-info,#simulation-download-pdf').fadeIn();
                $('html, body').animate({scrollTop : 0},800);
            });
        });

        $('#simulation-extra-info .back_btn').click(function(e){
            e.preventDefault();
            $('#simulation-extra-info').fadeOut().promise().done(function(){
                $('#simulation-results,#simulation-form').fadeIn();
            });
        });


        //$('#confirm-simulation').click(function(e){
        //
        //    e.preventDefault();
        //
        //    var loading_screen = pleaseWait(please_wait_options);
        //
        //    var car_type = $('#car-select option:selected').val();
        //    var fuel_select = $('#fuel-select option:selected').val();
        //    var km_select = $('#km-select option:selected').val();
        //    var mehir_sofi = $('#mehir_sofi').val();
        //    var car_price = $('#car-select option:selected').data('price');
        //    var leasing_company = $('#car-select option:selected').data('leasing-company');
        //    var yami_hofesh = $('#yami_hofesh').val();
        //    var hofesh = $('#hofesh').val();
        //    var mehir_mekori = $('#mehir_mekori').val();
        //
        //    var accessory_name_1 = $('#accessory_name_1').val();
        //    var accessory_name_2 = $('#accessory_name_2').val();
        //    var accessory_name_3 = $('#accessory_name_3').val();
        //    var accessory_price_1 = $('#accessory_price_1').val();
        //    var accessory_price_2 = $('#accessory_price_2').val();
        //    var accessory_price_3 = $('#accessory_price_3').val();
        //
        //    $.ajax({
        //        url: 'index.php?option=com_benefit&task=data.createSimulationPdf',
        //        data: 'car_type='+car_type+'&fuel_select='+fuel_select+'&km_select='+km_select+'&mehir_mekori='+mehir_mekori+'&mehir_sofi='+mehir_sofi+'&car_price='+car_price+'&accessory_name_1='+accessory_name_1+'&accessory_name_2='+accessory_name_2+'&accessory_name_3='+accessory_name_3+'&accessory_price_1='+accessory_price_1+'&accessory_price_2='+accessory_price_2+'&accessory_price_3='+accessory_price_3+'&leasing_company='+leasing_company+'&yami_hofesh='+yami_hofesh+'&hofesh='+hofesh,
        //        type: 'POST',
        //        dataType: 'json',
        //        success: function (response) {
        //            if(response.status){
        //
        //                console.log('success');
        //                //window.open(response.data,'_blank');
        //
        //                downloadPdf(response.data);
        //
        //                loading_screen.finish();
        //
        //            }
        //            else{
        //                console.log(response.message);
        //            }
        //
        //        },
        //        error: function (xhr, status, error) {
        //
        //            console.log(error);
        //
        //        }
        //    });
        //
        //});


        $.extend( $.validator.messages, {
            required: "השדה הזה הינו שדה חובה",
            remote: "נא לתקן שדה זה",
            email: "נא למלא כתובת דוא\"ל חוקית",
            url: "נא למלא כתובת אינטרנט חוקית",
            date: "נא למלא תאריך חוקי",
            dateISO: "נא למלא תאריך חוקי (ISO)",
            number: "נא למלא מספר",
            digits: "נא למלא רק מספרים",
            creditcard: "נא למלא מספר כרטיס אשראי חוקי",
            equalTo: "נא למלא את אותו ערך שוב",
            extension: "נא למלא ערך עם סיומת חוקית",
            maxlength: $.validator.format( ".נא לא למלא יותר מ- {0} תווים" ),
            minlength: $.validator.format( "נא למלא לפחות {0} תווים" ),
            rangelength: $.validator.format( "נא למלא ערך בין {0} ל- {1} תווים" ),
            range: $.validator.format( "נא למלא ערך בין {0} ל- {1}" ),
            max: $.validator.format( "נא למלא ערך קטן או שווה ל- {0}" ),
            min: $.validator.format( "נא למלא ערך גדול או שווה ל- {0}" )
        } );




        $("#simulation_sms_code_form").validate({
            errorPlacement: function(error,element) {
                return true;
            },
            submitHandler: function(form){
                loading_screen = pleaseWait(please_wait_options);
                var code = $('#simulation_sms_code_input').val();
                $.ajax({
                    url: 'index.php?option=com_benefit&task=data.validate_sms_code',
                    data: 'code='+code,
                    type: 'POST',
                    dataType: 'json',
                    success: function (response) {
                        if(response.status){


                            $('#simulation_sms_code_modal').modal('hide');

                            //generatePdf();

                            var file_name = $('#simulation-send-pdf').attr('data-file-name');
                            var info_email = $('#simulation-send-pdf').attr('data-info-email');
                            send_pdf_by_email(file_name,info_email);


                        }
                        else{
                            alert(response.msg);
                            loading_screen.finish();
                        }

                    },
                    error: function (xhr, status, error) {
                        console.log(error);
                        loading_screen.finish();
                    }
                });
            }
        });



        function simulation_validate_by_sms(){

            $.ajax({
                url: 'index.php?option=com_benefit&task=data.validate_by_sms',
                data: '',
                type: 'POST',
                dataType: 'json',
                success: function (response) {
                    if(response.status){
                        console.log(response.msg);

                        $('#simulation_sms_code_input').val('');

                        $('#simulation_sms_code_modal').modal({
                            backdrop:false,
                            keyboard: false
                        });

                    }
                    else{
                        console.log(response.msg);
                    }

                },
                error: function (xhr, status, error) {
                    console.log(error);
                }
            });
        }

        // $("#simulation-log").click(function(e) {
        //
        //     e.preventDefault();
        //
        //     simulation_write_log();
        // });

        function simulation_write_log(){

            var car_type = $('#car-select option:selected').val();
            var fuel_cover = $('#fuel-select option:selected').val();
            var km_cover = $('#km-select option:selected').text();
            var hafhata_leasing = $('.hafhata_leasing').val();


            var obj = {
                car_type : car_type,
                fuel_cover : fuel_cover,
                km_cover : km_cover,
                hafhata_leasing : hafhata_leasing
            };

            $.ajax({
                url: 'index.php?option=com_benefit&task=data.simulationWriteLog',
                data: obj,
                type: 'POST',
                dataType: 'json',
                success: function (response) {
                    if(response.status){
                        console.log(response.msg);
                    }
                    else{
                        console.log(response.msg);
                    }
                },
                error: function (xhr, status, error) {
                    console.log(error);
                }
            });
        }




        $("#simulation-extra-info-form").validate({
            rules: {
                info_email: {
                    required: true,
                    email: true
                }
            },
            errorElement: "div",
            errorPlacement: function(error, element) {
                element.after(error);
            },
            submitHandler: function(form){

                generatePdf();

            }
        });



        function send_pdf_by_email(filename,to){
            var simulation_message = $('#simulation-message');
            $('div',simulation_message).hide();
            $.ajax({
                url: 'index.php?option=com_benefit&task=data.sendSimulationPdfViaEmail',
                data: "file_name="+filename+"&to="+to,
                type: 'POST',
                dataType: 'json',
                success: function (response) {
                    if(response.status){

                        console.log('success');


                        $('#simulation-extra-info').fadeOut().promise().done(function(){
                            simulation_message.fadeIn();
                            $('.success',simulation_message).fadeIn();
                        });


                        $('#simulation-extra-info-form input').prop('disabled',false);


                        simulation_write_log();

                        loading_screen.finish();

                    }
                    else{
                        console.log(response.message);

                        $('#simulation-extra-info').fadeOut().promise().done(function(){
                            simulation_message.fadeIn();
                            $('.error',simulation_message).fadeIn();
                        });

                        $('#simulation-extra-info-form input').prop('disabled',false);

                        loading_screen.finish();
                    }

                },
                error: function (xhr, status, error) {

                    console.log(error);

                    $('#simulation-extra-info').fadeOut().promise().done(function(){
                        simulation_message.fadeIn();
                        $('.error',simulation_message).fadeIn();
                    });

                    $('#simulation-extra-info-form input').prop('disabled',false);

                    loading_screen.finish();
                }
            });
        }

        function downloadPdf(filename){
            //window.location="index.php?option=com_benefit&task=data.downloadPdf&file_name="+filename;
            window.open("index.php?option=com_benefit&task=data.downloadPdf&file_name="+filename,'_blank');
        };



        $('#simulation-send-pdf').click(function(e){

            e.preventDefault();
            e.stopPropagation();
            e.stopImmediatePropagation();

            simulation_validate_by_sms();

        });

        $('#simulation-edit-pdf').click(function(e){

            e.preventDefault();
            e.stopPropagation();
            e.stopImmediatePropagation();

            loading_screen = pleaseWait(please_wait_options);

            $('#simulation-send-pdf,#simulation-edit-pdf').fadeOut().promise().done(function(){
                $('#simulation_sms_code_input').val('');
                $('#simulation-extra-info-form input').prop('disabled',false);
                $('#simulation-download-pdf').fadeIn();

                loading_screen.finish();
            });

        })



        function generatePdf(){

            loading_screen = pleaseWait(please_wait_options);

            var data = {
                car_type : $('#car-select option:selected').val(),
                fuel_select : $('#fuel-select option:selected').val(),
                km_select : $('#km-select option:selected').val(),
                mehir_sofi : $('#mehir_sofi').val(),
                car_price : $('#car-select option:selected').data('price'),
                leasing_company : $('#car-select option:selected').data('leasing-company'),
                yami_hofesh : $('#yami_hofesh').val(),
                hofesh : $('#hofesh').val(),
                mehir_mekori : $('#mehir_mekori').val(),
                accessory_name_1 : $('#accessory_name_1').val(),
                accessory_name_2 : $('#accessory_name_2').val(),
                accessory_name_3 : $('#accessory_name_3').val(),
                accessory_price_1 : $('#accessory_price_1').val(),
                accessory_price_2 : $('#accessory_price_2').val(),
                accessory_price_3 : $('#accessory_price_3').val(),
                info_employee_number : $('#info_employee_number').val(),
                info_unit : $('#info_unit').val(),
                info_partner_full_name : $('#info_partner_full_name').val(),
                info_car_color : $('#info_car_color').val(),
                info_create_year : $('#info_create_year').val(),
                info_birth_date : $('#info_birth_date').val(),
                info_home_address : $('#info_home_address').val(),
                info_email : $('#info_email').val(),
                info_fuel_company : $('#info_fuel_company').val(),
                info_office_phone : $('#info_office_phone').val(),
                info_license_number : $('#info_license_number').val(),
                info_license_valid : $('#info_license_valid').val(),
                info_gishur_date : $('#info_gishur_date').val(),
                info_gishur_address : $('#info_gishur_address').val()
            };


            $.ajax({
                url: 'index.php?option=com_benefit&task=data.createSimulationPdf',
                data: data,
                type: 'POST',
                dataType: 'json',
                success: function (response) {
                    if(response.status){

                        console.log('success');
                        //window.open(response.data,'_blank');
                        downloadPdf(response.data);


                        $('#simulation-send-pdf').attr('data-file-name',response.data);
                        $('#simulation-send-pdf').attr('data-info-email',data.info_email);


                        $('#simulation-download-pdf').fadeOut().promise().done(function(){
                            $('#simulation-extra-info-form input').prop('disabled',true);
                            $('#simulation-send-pdf,#simulation-edit-pdf').fadeIn();
                        });


                        //send_pdf_by_email(response.data,data.info_email);
                        loading_screen.finish();
                    }
                    else{
                        console.log(response.message);
                    }

                },
                error: function (xhr, status, error) {

                    console.log(error);

                }
            });
        }
    });



    //function stopTask(es) {
    //    es.close();
    //    console.log(es +' Interrupted');
    //}
    //
    //function addLog(message) {
    //    var r = document.getElementById('results');
    //    r.innerHTML += message + '<br>';
    //    r.scrollTop = r.scrollHeight;
    //}

    //$("#upload-simulation-1-csv").click(function(e){
    //
    //    var percent = 0;
    //    $('#progress-simulation-1').css('width', percent+'%').attr('aria-valuenow', percent);
    //
    //    es_simulation_1 = new EventSource('./cronjobs/dbo_simulation_1.php');
    //
    //    //a message is received
    //    es_simulation_1.addEventListener('message', function(e) {
    //        var result = JSON.parse( e.data );
    //
    //        $('#numbers-simulation-1').html(result.message);
    //
    //        if(e.lastEventId == 'CLOSE') {
    //            //console.log('Received CLOSE closing');
    //            es_simulation_1.close();
    //        }
    //        else {
    //            var percent = result.progress;
    //            $('#progress-simulation-1').css('width', percent+'%').attr('aria-valuenow', percent);
    //        }
    //    });
    //
    //    es_simulation_1.addEventListener('error', function(e) {
    //        //console.log('Error occurred');
    //        es_simulation_1.close();
    //    });
    //});



    //$(function(){
    //    var checkAll = $("#check-all");
    //    var checkboxes = $('#datatable-checkbox tbody input[type="checkbox"]');
    //
    //    //$('input').iCheck();
    //
    //    checkAll.on('ifChecked ifUnchecked', function(event) {
    //        if (event.type == 'ifChecked') {
    //            checkboxes.iCheck('check');
    //        } else {
    //            checkboxes.iCheck('uncheck');
    //        }
    //    });
    //
    //    checkboxes.on('ifChanged', function(event){
    //        if(checkboxes.filter(':checked').length == checkboxes.length) {
    //            checkAll.prop('checked', 'checked');
    //        } else {
    //            checkAll.removeProp('checked');
    //        }
    //        checkAll.iCheck('update');
    //    });
    //})


    function equalHeights() {
        var highestBox = 0;
        $('#simulation-comments .x_content').each(function () {
            if ($(this).height() > highestBox) {
                highestBox = $(this).height();
            }
        });
        $('#simulation-comments .x_content').height(highestBox);
    }


    $('#update-km-confirm').click(function(e){
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
        var update_km_field = $('#update-km-field').val();

        if(update_km_field<1000 || update_km_field>200000){
            $('#update-km-msg .error-validation').show();
            return;
        }
        $('#update-km-msg .msg').hide();
        $.ajax({
            url: 'index.php?option=com_benefit&task=data.updateKm',
            data: 'km='+update_km_field,
            type: 'POST',
            dataType: 'json',
            success: function (response) {
                if(response.status){
                    $('#update-km-msg .success').show();
                }
                else{
                    $('#update-km-msg .error').show();
                }
            },
            error: function (xhr, status, error) {
                $('#update-km-msg .error').show();
            }
        });

    });




    //$(".upload").click(function(e){
    //
    //    var es;
    //    var percent = 0;
    //    var upload_container = $(this).parent();
    //    var progress_bar = upload_container.find('.progress-bar');
    //    var numbers = upload_container.find('.numbers');
    //
    //    progress_bar.css('width', percent+'%').attr('aria-valuenow', percent);
    //
    //    var file_name = $(this).data('file-name');
    //
    //
    //    //index.php?option=com_benefit&task=data.uploadRowDataBll
    //
    //    es = new EventSource('./cronjobs/'+ file_name + '.php');
    //
    //    es.addEventListener('message', function(e) {
    //        var result = JSON.parse( e.data );
    //
    //        numbers.html(result.message);
    //
    //        if(e.lastEventId == 'CLOSE') {
    //            es_users.close();
    //        }
    //        else {
    //            var percent = result.progress;
    //            progress_bar.css('width', percent+'%').attr('aria-valuenow', percent);
    //        }
    //    });
    //
    //    es.addEventListener('error', function(e) {
    //        es.close();
    //    });
    //});
})(jQuery);